export function normalizeProcurementInformation(procurement: any) {
    return {
        firstName: procurement?.sellerInformationFirstName || "",
        lastName: procurement?.sellerInformationLastName || "",
        email: procurement?.emailAddress || "",
        phoneNumber: procurement?.phoneNumber || "",
        vin: procurement?.vehicleVIN || "",
        year: String(procurement?.vehicleYear) || "2020",
        make: procurement?.vehicleMake || "",
        model: procurement?.vehicleModel || "",
        trim: procurement?.vehicleTrim || "",
    };
}
