import {APICore} from "./apiCore";

import {ICreateProcurement, IProcurement, IProduct} from "../../shared/types";

const api = new APICore(`${process.env.REACT_APP_IGNITION_API}/` || "https://dev.ignition-api.motorenn.com/");

const PROCUREMENT = "procurement";

// CREATE
export const createProcurement = async (data: ICreateProcurement): Promise<IProcurement> => (await api.create(`inventory/${PROCUREMENT}`, data)).data;

// READ
export const listProducts = async (query: string): Promise<IProduct[]> => (await api.get("products", {status: query})).data;
