import config from "../../config";

import {APICore} from "./apiCore";

const api = new APICore(config.API_URL);

export const getAllUsers = async () => (await api.get("/users")).data;

export const getVehicleDetailByVin = async (vin: string) => (await api.get(`/vehicles/vin/${vin}`))?.data;

export const getMakes = async () => (await api.get(`/vehicles/makes`)).data;

export const getMakeModels = async (make: string) => (await api.get(`/vehicles/makes/${make}/models`)).data;

export const getMakeModelTrims = async (modelId: string) => (await api.get(`/vehicles/model/${modelId}/trims`)).data;

export const getMakeModelTrimTypeDrivetrainsByYear = async (year: string) => (await api.get(`/vehicles/year/${year}/mmttd`))?.data;
