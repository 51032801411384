export function safeJsonParse(str: string | null | undefined): any {
    let json = {};

    if (!str) {
        return json;
    }

    try {
        json = JSON.parse(str);
    } catch (e) {
        // fail silently
    }

    return json;
}
