import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";
import startCase from "lodash/startCase";

export const arrayToMultipleMap = (array: any[], field = "id", transform = (e: any) => e, transformValue = (t: any) => t) => {
    if (!array) {
        return {};
    }
    return array.reduce((prev, item) => {
        if (!prev.hasOwnProperty(transform(item[field]))) {
            prev[item[field]] = [];
        }
        prev[transform(item[field])].push(transformValue(item));
        return prev;
    }, {});
};

export const camelToSnake = (obj: any) => mapKeys(obj, (value, key) => snakeCase(key));

export const camelToTitle = (value: string) => startCase(value.replace(/([A-Z])/g, " $1"));

export const formatCurrency = (val: number) =>
    val?.toLocaleString("us-EN", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
    });
