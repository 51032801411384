import {camelToSnake} from "../../shared/utils/data";

import {APICore} from "./apiCore";

import {mockData} from "./mock/data.mock"; // TODO; this will only be included for dev builds in another file

const api = new APICore(process.env.REACT_APP_DATA_API || "https://local.data-api.motorenn.com:5000/");

export interface PricePredictionQuery {
    year: string;
    make: string;
    model: string;
    trim?: string;
    type: string;
    drivetrain: string;
    mileageBin: string;
    startDate: string;
}

const getMakesByYear = async (year: string) => (await api.get(`/api/v1/makes/${year}`)).data;

const getMakeModels = async (year: string, make: string) => (await api.get(`/api/v1/makes/${year}/${make}/models`)).data;

const getMakeModelTrims = async (year: string, make: string, model: string) => (await api.get(`/api/v1/makes/${year}/${make}/models/${model}/trims`)).data;

const getYearsForMakeModelTrims = async (year: string, make: string, model: string, type: string) =>
    (await api.get(`/api/v1/makes/${year}/${make}/models/${model}/trims/type/${type}/years`)).data;

const getBodyTypesByTrim = async (year: string, make: string, model: string, trim: string) =>
    (await api.get(`/api/v1/makes/${year}/${make}/models/${model}/trims/${trim}/types`)).data;

const getDrivetrainsByType = async (year: string, make: string, model: string, trim: string, type: string) =>
    (await api.get(`/api/v1/makes/${year}/${make}/models/${model}/trims/${trim}/types/${type}/drivetrains`)).data;

const getAllBodyTypes = async () => (await api.get(`/api/v1/body_types`)).data;

/*
{"year": "2017",
"make": "Porsche",
"model": "911",
"trim": "Carrera",
"type": "Coupe",
"drivetrain": "RWD",
"mileage_bin": "1000",
"start_date": "2022-01-01",
"all_mileage_predictions": true}

{year: "2017",
make: "Lamborghini",
model: "Aventador",
trim: "LP 750-4 Superveloce",
type: "Coupe",
drivetrain: "AWD",
mileage_bin: "11000",
start_date: ""}

{"year": "2017",
"make": "Lamborghini",
"model": "Aventador",
"trim": "LP 750-4 Superveloce",
"type": "Coupe",
"drivetrain": "AWD",
"mileage_bin": "11000"}
*/
const getPricePrediction = async (predictionQuery: PricePredictionQuery) => (await api.create(`/api/v1/predict`, camelToSnake(predictionQuery))).data;

const getPricePredictionSimilarVehicles = async (predictionQuery: PricePredictionQuery) =>
    (await api.create(`/api/v1/predict/similar_vehicles`, camelToSnake(predictionQuery))).data;

const getPricePredictionDetail = async (predictionQuery: PricePredictionQuery) =>
    (await api.create(`/api/v1/predict`, camelToSnake({...predictionQuery, all_predictions: true}))).data;

export {
    getMakesByYear,
    getMakeModels,
    getMakeModelTrims,
    getAllBodyTypes,
    getPricePrediction,
    getPricePredictionSimilarVehicles,
    getPricePredictionDetail,
    getYearsForMakeModelTrims,
    getBodyTypesByTrim,
    getDrivetrainsByType,
};
