export const GOOGLE_API_BASE_URL = process.env.REACT_APP_GOOGLE_API_BASE_URL || "";

export const CsrfTokenURL = "/backend/csrf-token";
export const CsrfTokenHeader = "x-csrf-token";
export const CsrfTokenRefreshMs = 60 * 29 * 1000;
export const CsrfTokenExpireTime = "30m";

export const Social = {
    facebook: "facebook",
    google: "google",
    twitter: "twitter",
    linkedin: "linkedin",
};

export const LoadingStates = {
    signup: "signup",
    facebook: "facebook",
    google: "google",
    forgotPassword: "forgotPassword",
    resetPassword: "resetPassword",
    userExists: "userExists",
};

export const GoogleAppId = "1023948693540-a3kuosho7gl39qitl6csg8agi37aem8f.apps.googleusercontent.com";
export const FacebookAppId = "199825268508218";
export const FacebookApiVersion = "v3.1";

export const LoginActions = {
    signup: "signup",
    signin: "signin",
    logout: "logout",
};
