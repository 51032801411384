import React, {useMemo} from "react";
import {Row} from "react-bootstrap";
import {useLocation} from "react-router-dom";

import {publicRoutes, authProtectedRoutes, RoutesProps} from "../../routes";

import PageTitle from "../PageTitle";

interface StandardPageProps {
    title: string;
    breadcrumbs?: any[];
    children: any;
}

const getRouteDetailByPath = (targetPath: string, searchRoutes: any[]): any =>
    searchRoutes.reduce((foundPaths, route: RoutesProps) => {
        const {path, children, ...routeInfo} = route;
        if (children) {
            const matchedPaths = getRouteDetailByPath(targetPath, children);
            if (matchedPaths.length) return [...foundPaths, {path, ...routeInfo}, ...matchedPaths];
        }
        return targetPath === path ? [...foundPaths, route] : foundPaths;
    }, []);

const StandardPage = ({title, breadcrumbs, children}: StandardPageProps) => {
    const location = useLocation();

    const routes = [...authProtectedRoutes, ...publicRoutes];

    const crumbs = useMemo(() => {
        return breadcrumbs || getRouteDetailByPath(location.pathname, routes).map(({name, path}: RoutesProps) => ({label: name, path, active: path === location.pathname}));
    }, [breadcrumbs]);

    return (
        <>
            <PageTitle breadCrumbItems={crumbs} title={title} />
            <Row>{children}</Row>
        </>
    );
};

export default StandardPage;
