import {APICore} from "./apiCore";

import {GOOGLE_API_BASE_URL, CsrfTokenHeader, Social, LoginActions} from "../../shared/constants";

import config from "../../config";

const api = new APICore(config.API_URL);

const login = async (params: {username: string; password: string}) => {
    const baseUrl = "/auth/login";
    return api.create(`${baseUrl}`, params);
};

const logout = () => {
    const baseUrl = "/auth/logout/";
    return api.create(`${baseUrl}`, {});
};

function signup(params: {fullname: string; email: string; password: string}) {
    const baseUrl = "/auth/register/";
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: {email: string}) {
    const baseUrl = "/auth/forget-password/";
    return api.create(`${baseUrl}`, params);
}

const googleLogin = async (params: {token: string}) => {
    const baseUrl = "/auth/google/login";
    return api.get(`${baseUrl}`, params);

    /*
        // 1st validate access_token to make sure it's valid with google
        // otherwise we might get fake tokens from malicious parties
        const url = `${GOOGLE_API_BASE_URL}oauth2/v3/tokeninfo?id_token=${query.token}`;
        const response = api.create({uri: url, json: true});
        // if invalid token
        if (response.error_description) {
            req.Logger.error(response.error_description);
            throw new AuthenticationError(AuthenticationErrorMessage.invalidGOOGLEToken);
        }

        const {email, name, aud: appClientId, sub: socialId, given_name: firstName, family_name: lastName, picture: avatar} = response;

        if (appClientId !== googleKeys.public) {
            throw new AuthenticationError(AuthenticationErrorMessage.invalidGOOGLEToken);
        }

        // check if socialConection exists
        const existingSocial = await req.Models.SocialConnection.findOne({
            where: {type: Social.google, socialId},
        });

        let id;
        let existingUser = true;
        if (!existingSocial) {
            // if it doesn't exits create a new user
            let user = await req.Models.User.findOne({where: {email}});

            if (!user) {
                user = await req.Models.User.create({
                    email,
                    lastName,
                    firstName,
                    avatar,
                    validated: 1,
                    isInvite: false,
                });
                existingUser = false;
                if (!user) {
                    throw new Error("Server Error");
                }
            } else if (!user.get("validated") || user.get("isInvite")) {
                await user.update({
                    lastName: user.get("lastName") || lastName,
                    firstName: user.get("firstName") || firstName,
                    avatar: user.get("avatar") || avatar,
                    validated: 1,
                    isInvite: false,
                });
            }

            id = user.get("id");
            // finally store the new connection
            await req.Models.SocialConnection.create({
                type: "google",
                socialId,
                userId: id,
            });
        } else {
            // if user exists just get his user id
            id = existingSocial.get("userId");
        }

        // generate token and return cookie and user id
        const token = await encode({id}, req.Config.get("server").session.secret);
        const csrfToken = await createCsrfToken(req, id);
        const newUser = await req.Models.User.findOne({where: {id}});
        await req.Models.Cart.syncUserSession(id, req);
        return {
            cookie: {[tokenCookieName]: [token, {httpOnly: true}]},
            headers: {
                [CsrfTokenHeader]: csrfToken,
            },
            response: {
                id,
                displayName: newUser.get("firstName") || obfuscateEmailForDisplay(newUser.get("email")),
                action: existingUser ? LoginActions.signin : LoginActions.signup,
                isSuperAdmin: newUser.get("superAdmin"),
                firstName: newUser.get("firstName") || firstName,
                lastName: newUser.get("lastName") || lastName,
                email: newUser.get("email") || email,
            },
        };
    */
};

export {login, googleLogin, logout, signup, forgotPassword};
