import React from "react";

import Routes from "./routes/Routes";

import {configureFakeBackend} from "./helpers";

// Themes
// For Default import Theme.scss
// import './assets/scss/Theme.scss';

// For Dark import Theme-Dark.scss
import "./assets/scss/Theme-Dark.scss";

if (process.env.USE_BACKEND_MOCKS) {
    configureFakeBackend();
}

const App = () => <Routes />;

export default App;
