import React from "react";
import {Route, RouteProps} from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";

import StandardPage from "../components/StandardPage";

import {demoRoutes} from "./demo";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// dashboards
const DashboardLeads = React.lazy(() => import("../pages/dashboards/leads"));
const VehicleTrendsDashboard = React.lazy(() => import("../pages/dashboards/pricing"));
const VehicleInsightsDashboard = React.lazy(() => import("../pages/dashboards/vehicles"));

// inventory
const Inventory = React.lazy(() => import("../pages/inventory"));
const InventoryNew = React.lazy(() => import("../pages/inventory/new"));
const ProcurementNew = React.lazy(() => import("../pages/vehicles/procurement/new"));

// admin tools
const UsersManagement = React.lazy(() => import("../pages/admin/users"));

// dashboard
const EcommerceDashboard = React.lazy(() => import("../pages/demo/dashboard/Ecommerce/"));
const AnalyticsDashboard = React.lazy(() => import("../pages/demo/dashboard/Analytics/"));

// deals
const DealsLeads = React.lazy(() => import("../pages/deals/leads"));
const DealsLeadDetail = React.lazy(() => import("../pages/deals/leads/byId"));
const DealsBoard = React.lazy(() => import("../pages/deals/board/"));
const DealClients = React.lazy(() => import("../pages/deals/clients/"));
const DealClienDetail = React.lazy(() => import("../pages/deals/clients/byId"));

const Landing = React.lazy(() => import("../pages/demo/landing/"));
const Maintenance = React.lazy(() => import("../pages/demo/other/Maintenance"));

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));

// **** demo pages ****

export interface RoutesProps {
    path: RouteProps["path"];
    name?: string;
    component?: RouteProps["component"];
    route?: any;
    exact?: RouteProps["exact"];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: "/",
    exact: true,
    component: () => <Root />,
    route: Route,
};

// dashboards
const dashboardRoutes: RoutesProps = {
    path: "/dashboard",
    name: "Dashboards",
    icon: "airplay",
    header: "Navigation",
    children: [
        {
            path: "/dashboard/leads",
            name: "Leads",
            component: DashboardLeads,
            route: PrivateRoute,
        },
        {
            path: "/dashboard/vehicle/trends",
            name: "Vehicle Trends",
            component: VehicleTrendsDashboard,
            route: PrivateRoute,
        },
        {
            path: "/dashboard/vehicle/insights",
            name: "Vehicle Insights",
            component: VehicleInsightsDashboard,
            route: PrivateRoute,
        },
        {
            path: "/dashboard/activity",
            name: "Account Activity",
            component: StandardPage,
            route: PrivateRoute,
        },
        {
            path: "/dashboard/ecommerce",
            name: "Ecommerce",
            component: EcommerceDashboard,
            route: Route,
        },
        {
            path: "/dashboard/analytics",
            name: "Analytics",
            component: AnalyticsDashboard,
            route: Route,
        },
    ],
};

const dealsAppRoutes: RoutesProps = {
    path: "/deals",
    name: "Deals",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "calendar",
    component: StandardPage,
    header: "Deals",
    children: [
        {
            path: "/deals/clients/:id",
            name: "Client Details",
            component: DealClienDetail,
            route: PrivateRoute,
        },
        {
            path: "/deals/leads",
            name: "Leads",
            children: [
                {
                    path: "/deals/leads/:id",
                    name: "Lead Detail",
                    component: DealsLeadDetail,
                    route: PrivateRoute,
                },
                {
                    path: "/deals/leads",
                    name: "Leads",
                    component: DealsLeads,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: "/deals/vehicles",
            name: "Vehicle Trends",
            component: StandardPage,
            route: PrivateRoute,
        },
        {
            path: "/deals/board",
            name: "Board",
            component: DealsBoard,
            route: Route,
        },
        {
            path: "/deals/clients",
            name: "Clients",
            component: DealClients,
            route: PrivateRoute,
        },
    ],
};

const adminAppRoutes: RoutesProps = {
    path: "/admin",
    name: "Admin Tools",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "tool",
    component: UsersManagement,
    header: "Admin Tools",
    children: [
        {
            path: "/admin/users",
            name: "Users",
            component: UsersManagement,
            route: PrivateRoute,
        },
        {
            path: "/admin/scraper",
            name: "Scraper Configuration",
            component: StandardPage,
            route: PrivateRoute,
        },
        {
            path: "/admin/procurement",
            name: "Procurement",
            component: StandardPage,
            route: PrivateRoute,
        },
    ],
};

const inventoryAppRoutes: RoutesProps = {
    path: "/inventory",
    name: "Inventory",
    route: PrivateRoute,
    icon: "uil-car",
    header: "Inventory",
    children: [
        {
            path: "/inventory/vehicles",
            name: "Vehicles",
            component: Inventory,
            route: PrivateRoute,
        },
        {
            path: "/inventory/new",
            name: "New Inventory",
            component: InventoryNew,
            route: PrivateRoute,
        },
    ],
};

const vehiclesAppRoutes: RoutesProps = {
    path: "/vehicles",
    name: "Vehicles",
    route: PrivateRoute,
    icon: "uil-car",
    header: "Vehicles",
    children: [
        {
            path: "/vehicles/procurement/new",
            name: "New Procurement",
            component: ProcurementNew,
            route: PrivateRoute,
        },
        // {
        //     path: "/inventory/new",
        //     name: "New Inventory",
        //     component: InventoryNew,
        //     route: PrivateRoute,
        // },
    ],
};

// auth
const authRoutes: RoutesProps[] = [
    {
        path: "/auth/login",
        name: "Login",
        component: Login,
        route: Route,
    },
    {
        path: "/auth/register",
        name: "Register",
        component: Register,
        route: Route,
    },
    {
        path: "/auth/confirm",
        name: "Confirm",
        component: Confirm,
        route: Route,
    },
    {
        path: "/auth/forget-password",
        name: "Forget Password",
        component: ForgetPassword,
        route: Route,
    },
    {
        path: "/auth/lock-screen",
        name: "Lock Screen",
        component: LockScreen,
        route: Route,
    },
    {
        path: "/auth/logout",
        name: "Logout",
        component: Logout,
        route: Route,
    },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
    {
        path: "/landing",
        name: "landing",
        component: Landing,
        route: Route,
    },
    {
        path: "/maintenance",
        name: "Maintenance",
        component: Maintenance,
        route: Route,
    },
    {
        path: "/error-404",
        name: "Error - 404",
        component: Error404,
        route: Route,
    },
    {
        path: "/error-500",
        name: "Error - 500",
        component: Error500,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== "undefined") {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute, dashboardRoutes, dealsAppRoutes, adminAppRoutes, inventoryAppRoutes, vehiclesAppRoutes, ...demoRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes};
