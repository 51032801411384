const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export function formatPrice(value: number | string) {
    return formatter.format(typeof value === "string" ? Number(value) : value);
}
