import {APICore} from "./apiCore";

const api = new APICore(`${process.env.REACT_APP_IGNITION_API}/legacy/pricing/` || "https://dev.ignition-api.motorenn.com/legacy/pricing/");

const getCars = async (requestBody: object) => (await api.create(`cars`, requestBody)).data;

const getChart = async (requestBody: object) => (await api.create(`chart`, requestBody)).data;

const getModels = async (make: string) => (await api.get(`make/${make}/models`)).data?.results;

const getTrims = async (make: string, models: string) => (await api.get(`make/${make}/models/${models}/trims`)).data?.results;

export {getCars, getChart, getModels, getTrims};
