export const getProductName = (product: Record<string, any>) => {
    if (!product) {
        return "";
    }

    if (product.p_name) {
        return product.p_name;
    }

    return `${product.p_year} ${product.p_make} ${product.p_model}`;
};
