import jwtDecode from "jwt-decode";
import axios from "axios";

import config from "../../config";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

/*
axios.interceptors.request.use(
    config => {
        console.log("*** axios interceptor REQUEST", {config});
        return config;
    },
    error => {
        console.log("*** axios interceptor REQUEST ERROR", {error});
        return Promise.reject(error);
    },
);

// intercepting to capture errors
axios.interceptors.response.use(
    response => {
        console.log("*** axios interceptor", {response});
        return response;
    },
    error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        console.log("*** axios interceptor RESPONSE ERROR", {error});

        if (error && error.response && error.response.status === 404) {
            // window.location.href = '/not-found';
        } else if (error && error.response && error.response.status === 403) {
            window.location.href = "/access-denied";
        } else {
            switch (error.response.status) {
                case 401:
                    message = "Invalid credentials";
                    break;
                case 403:
                    message = "Access Forbidden";
                    break;
                case 404:
                    message = "Sorry! the data you are looking for could not be found";
                    break;
                default: {
                    message = error.response && error.response.data ? error.response.data["message"] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    },
);
*/

const AUTH_SESSION_KEY = `${config.APP_NAME}_user`;

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
    if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    else delete axios.defaults.headers.common["Authorization"];
};

const getUserFromCookie = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    console.log("** getUserFromCookie", user);
    return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

class APICore {
    private axios;
    constructor(baseURL: string = "") {
        this.axios = axios.create({baseURL, headers: {post: {"Content-Type": "application/json"}}});
        this.axios.defaults.baseURL = baseURL || config.API_URL;
        this.axios.interceptors.request.use(
            config => {
                // TODO; anything?
                // console.log("*** axios interceptor REQUEST", {config});
                return config;
            },
            error => {
                return Promise.reject(error);
            },
        );

        // intercepting to capture errors
        this.axios.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                let message;

                console.log("*** interceptors.response", {error});
                if (error && error.response && error.response.status === 404) {
                    // window.location.href = '/not-found';
                } else if (error && error.response && error.response.status === 403) {
                    window.location.href = "/access-denied";
                } else {
                    switch (error.response.status) {
                        case 401:
                            message = "Invalid credentials";
                            break;
                        case 403:
                            message = "Access Forbidden";
                            break;
                        case 404:
                            message = "Sorry! the data you are looking for could not be found";
                            break;
                        default: {
                            message = error.response && error.response.data ? error.response.data["message"] : error.message || error;
                        }
                    }
                    return Promise.reject(message);
                }
            },
        );

        this.setUserInSession();
    }

    get = (url: string, params: any = null) => this.axios.get(`${url}`, {params});

    getFile = (url: string, params: any) => this.axios.get(`${url}`, {params, responseType: "blob"});

    getMultiple = (urls: string, params: any) => {
        const reqs = [];
        let queryString = "";
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map(key => key + "=" + params[key])
                      .join("&")
                : "";
        }

        for (const url of urls) {
            reqs.push(this.axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    create = async (url: string, data: any) => this.axios.post(url, data);

    updatePatch = (url: string, data: any) => this.axios.patch(url, data);

    update = (url: string, data: any) => this.axios.put(url, data);

    delete = (url: string) => this.axios.delete(url);

    createWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...this.axios.defaults.headers,
                "content-type": "multipart/form-data",
            },
        };
        return this.axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...this.axios.defaults.headers,
                "content-type": "multipart/form-data",
            },
        };
        return this.axios.patch(url, formData, config);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();

        if (!user) {
            return false;
        }
        const decoded: any = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn("access token expired");
            return false;
        } else {
            return true;
        }
    };

    setLoggedInUser = (session: any) => {
        if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            if (sessionStorage.getItem(AUTH_SESSION_KEY)) {
                sessionStorage.removeItem(AUTH_SESSION_KEY);
            }
        }
        if (session !== null) {
            this.setAuthorization(session.token);
        }
    };

    getLoggedInUser = () => getUserFromCookie();

    setUserInSession = (modifiedUser: any = {}) => {
        let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const {token, user} = JSON.parse(userInfo);
            this.setLoggedInUser({token, user, ...modifiedUser});
        }
    };

    /**
     * Sets the default authorization
     * @param {*} token
     */
    setAuthorization = (token: string | null) => {
        if (token) {
            this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        } else {
            delete this.axios.defaults.headers.common["Authorization"];
        }
    };
}

const user = getUserFromCookie();
if (user) {
    const {token} = user;
    if (token) {
        setAuthorization(token);
    }
}

export {APICore, setAuthorization};
