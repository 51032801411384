import PrivateRoute from "./PrivateRoute";
import {Route} from "react-router-dom";
import {RoutesProps} from "./index";
import React from "react";

// dashboard
const EcommerceDashboard = React.lazy(() => import("../pages/demo/dashboard/Ecommerce/"));
const AnalyticsDashboard = React.lazy(() => import("../pages/demo/dashboard/Analytics/"));

// apps
const CalendarApp = React.lazy(() => import("../pages/demo/apps/Calendar/"));
const Projects = React.lazy(() => import("../pages/demo/apps/Projects/"));
const ProjectDetail = React.lazy(() => import("../pages/demo/apps/Projects/Detail/"));
// - chat
const ChatApp = React.lazy(() => import("../pages/demo/apps/Chat/"));
// - email
const Inbox = React.lazy(() => import("../pages/demo/apps/Email/Inbox"));
const EmailDetail = React.lazy(() => import("../pages/demo/apps/Email/Detail"));
const EmailCompose = React.lazy(() => import("../pages/demo/apps/Email/Compose"));
// - tasks
const TaskList = React.lazy(() => import("../pages/demo/apps/Tasks/List/"));
const Kanban = React.lazy(() => import("../pages/demo/apps/Tasks/Board/"));
// - file
const FileManager = React.lazy(() => import("../pages/demo/apps/FileManager"));

// -other
const Starter = React.lazy(() => import("../pages/demo/other/Starter"));
const Profile = React.lazy(() => import("../pages/demo/other/Profile"));
const Activity = React.lazy(() => import("../pages/demo/other/Activity"));
const Invoice = React.lazy(() => import("../pages/demo/other/Invoice"));
const Pricing = React.lazy(() => import("../pages/demo/other/Pricing"));

// uikit
const UIElements = React.lazy(() => import("../pages/demo/uikit"));

// widgets
const Widgets = React.lazy(() => import("../pages/demo/widgets/"));

// icons
const Unicons = React.lazy(() => import("../pages/demo/icons/Unicons"));
const FeatherIcons = React.lazy(() => import("../pages/demo/icons/Feather/"));
const BootstrapIcon = React.lazy(() => import("../pages/demo/icons/Bootstrap/"));

// forms
const BasicForms = React.lazy(() => import("../pages/demo/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/demo/forms/Advanced"));
const FormValidation = React.lazy(() => import("../pages/demo/forms/Validation"));
const FormWizard = React.lazy(() => import("../pages/demo/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/demo/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/demo/forms/Editors"));

// charts
const Charts = React.lazy(() => import("../pages/demo/charts/"));

// tables
const BasicTables = React.lazy(() => import("../pages/demo/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/demo/tables/Advanced"));

// maps
const GoogleMaps = React.lazy(() => import("../pages/demo/maps/GoogleMaps"));
const VectorMaps = React.lazy(() => import("../pages/demo/maps/VectorMaps"));

// dashboards
const demoDashboardRoutes: RoutesProps = {
    path: "/demo/dashboard",
    name: "Dashboards",
    icon: "airplay",
    header: "Navigation",
    children: [
        {
            path: "/demo/dashboard/ecommerce",
            name: "Ecommerce",
            component: EcommerceDashboard,
            route: Route,
        },
        {
            path: "/demo/dashboard/analytics",
            name: "Analytics",
            component: AnalyticsDashboard,
            route: Route,
        },
    ],
};

const calendarAppRoutes: RoutesProps = {
    path: "/apps/calendar",
    name: "Calendar",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "calendar",
    component: CalendarApp,
    header: "Apps",
};

const chatAppRoutes: RoutesProps = {
    path: "/apps/chat",
    name: "Chat",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "message-square",
    component: ChatApp,
};

const emailAppRoutes: RoutesProps = {
    path: "/apps/email",
    name: "Email",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "mail",
    children: [
        {
            path: "/apps/email/inbox",
            name: "Inbox",
            component: Inbox,
            route: PrivateRoute,
        },
        {
            path: "/apps/email/details",
            name: "Email Details",
            component: EmailDetail,
            route: PrivateRoute,
        },
        {
            path: "/apps/email/compose",
            name: "Compose Email",
            component: EmailCompose,
            route: PrivateRoute,
        },
    ],
};

const projectAppRoutes: RoutesProps = {
    path: "/apps/projects",
    name: "Projects",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "uil-briefcase",

    children: [
        {
            path: "/apps/projects/list",
            name: "List",
            component: Projects,
            route: Route,
        },
        {
            path: "/apps/projects/details",
            name: "Detail",
            component: ProjectDetail,
            route: Route,
        },
    ],
};

const taskAppRoutes: RoutesProps = {
    path: "/apps/tasks",
    name: "Tasks",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "clipboard",
    children: [
        {
            path: "/apps/tasks/list",
            name: "Task List",
            component: TaskList,
            route: Route,
        },
        {
            path: "/apps/tasks/kanban",
            name: "Kanban",
            component: Kanban,
            route: Route,
        },
    ],
};

const fileAppRoutes: RoutesProps = {
    path: "/apps/file-manager",
    name: "File Manager",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "folder-plus",
    component: FileManager,
};

const appRoutes = [calendarAppRoutes, chatAppRoutes, emailAppRoutes, projectAppRoutes, taskAppRoutes, fileAppRoutes];

// pages
const extrapagesRoutes: RoutesProps = {
    path: "/pages",
    name: "Pages",
    icon: "package",
    header: "Custom",
    children: [
        {
            path: "/pages/starter",
            name: "Starter",
            component: Starter,
            route: PrivateRoute,
        },
        {
            path: "/pages/profile",
            name: "Profile",
            component: Profile,
            route: PrivateRoute,
        },
        {
            path: "/pages/activity",
            name: "Activity",
            component: Activity,
            route: PrivateRoute,
        },
        {
            path: "/pages/invoice",
            name: "Invoice",
            component: Invoice,
            route: PrivateRoute,
        },
        {
            path: "/pages/pricing",
            name: "Pricing",
            component: Pricing,
            route: PrivateRoute,
        },
    ],
};

// ui
const uiRoutes: RoutesProps = {
    path: "/components",
    name: "Components",
    icon: "package",
    header: "UI Elements",
    children: [
        {
            path: "/components/ui-elements",
            name: "UIElements",
            component: UIElements,
            route: PrivateRoute,
        },
        {
            path: "/components/widgets",
            name: "Widgets",
            component: Widgets,
            route: PrivateRoute,
        },
        {
            path: "/icons",
            name: "Icons",
            children: [
                {
                    path: "/icons/unicons",
                    name: "Unicons",
                    component: Unicons,
                    route: Route,
                },
                {
                    path: "/icons/feather",
                    name: "Feather",
                    component: FeatherIcons,
                    route: Route,
                },
                {
                    path: "/icons/bootstrap",
                    name: "Bootstrap Icon",
                    component: BootstrapIcon,
                    route: Route,
                },
            ],
        },
        {
            path: "/forms",
            name: "Forms",
            children: [
                {
                    path: "/forms/basic",
                    name: "Basic Elements",
                    component: BasicForms,
                    route: PrivateRoute,
                },
                {
                    path: "/forms/advanced",
                    name: "Form Advanced",
                    component: FormAdvanced,
                    route: PrivateRoute,
                },
                {
                    path: "/forms/validation",
                    name: "Form Validation",
                    component: FormValidation,
                    route: PrivateRoute,
                },
                {
                    path: "/forms/wizard",
                    name: "Form Wizard",
                    component: FormWizard,
                    route: PrivateRoute,
                },
                {
                    path: "/forms/upload",
                    name: "File Upload",
                    component: FileUpload,
                    route: PrivateRoute,
                },
                {
                    path: "/forms/editors",
                    name: "Editors",
                    component: Editors,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: "/components/charts",
            name: "Charts",
            component: Charts,
            route: PrivateRoute,
        },
        {
            path: "/tables",
            name: "Tables",
            children: [
                {
                    path: "/tables/basic",
                    name: "Basic",
                    component: BasicTables,
                    route: PrivateRoute,
                },
                {
                    path: "/tables/advanced",
                    name: "Advanced",
                    component: AdvancedTables,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: "/maps",
            name: "Maps",
            children: [
                {
                    path: "/maps/googlemaps",
                    name: "Google Maps",
                    component: GoogleMaps,
                    route: PrivateRoute,
                },
                {
                    path: "/maps/vectorMaps",
                    name: "Google Maps",
                    component: VectorMaps,
                    route: PrivateRoute,
                },
            ],
        },
    ],
};

export const demoRoutes = [demoDashboardRoutes, ...appRoutes, extrapagesRoutes, uiRoutes];
