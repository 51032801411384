export enum LeadSubTypeEnum {
    CONSIGNMENT = "consignment",
    CONTACT = "contact",
    ORDER = "order",
    PROCUREMENT = "procurement",
    TRADE_IN = "trade-in",
    INQUIRY = "inquiry",
}

export enum LeadDetailActionsEnum {
    OFFERS = "offers",
    PAYMENTS = "payments",
    DOCUMENTS = "documents",
}
