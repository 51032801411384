export const PaymentStatus: Record<string, number> = {
    created: 1,
    scheduled: 2,
    inProgress: 3,
    confirmed: 4,
    requestedRefund: 5,
    refunded: 6,
    failed: 7,
    onHold: 8,
    cancelled: 9,
};
