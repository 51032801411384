import {APICore} from "./apiCore";
import {DashboardLead, IActivity, IClient, ILead, ILeadDetail} from "../../shared/types";

const api = new APICore(`${process.env.REACT_APP_IGNITION_API}/` || "https://dev.ignition-api.motorenn.com/");

// CREATE
export const create = async (data: string): Promise<IActivity[]> => (await api.create(`activities`, data)).data;

export const createEstimatePurchase = async (data: any) => (await api.create("/activities/estimate-purchase", data)).data;

export const createTradeInOffer = async (data: any) => (await api.create("activities/trade-in-offer", data)).data;

// READ
export const listActivities = async (query: string, resolved: boolean): Promise<IActivity[]> => (await api.get("activities", {subtype: query, resolved})).data;

export const getActivity = async (id: string): Promise<IActivity> => (await api.get(`activities/${id}`)).data;

export const getLead = async (id: string): Promise<ILeadDetail> => (await api.get(`activities/lead/${id}`)).data;

export const listClients = async (): Promise<IClient[]> => (await api.get("activities/clients")).data;

export const listDashboardLeads = async (): Promise<DashboardLead> => (await api.get("activities/dashboard/leads")).data;

export const getClientById = async (id: string): Promise<IClient> => (await api.get(`activities/client/${id}`)).data;

export const getClientActivitiesById = async (id: string, subtype?: string): Promise<IActivity[] | ILead[]> => (await api.get(`activities/client/leads/${id}`, {subtype})).data;

// UPDATE
export const update = async (data: any, id: number): Promise<IActivity[]> => (await api.updatePatch(`activities/${id}`, data)).data;

export const updateTradeInOffer = async (id: number, data: any) => (await api.updatePatch(`activities/trade-in-offer/${id}`, data)).data;

// DELETE
export const deleteLead = async (id: number) => await api.delete(`activities/${id}`);
