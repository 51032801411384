import {APICore} from "./apiCore";

import {IDmvDocumentResponse} from "../../shared/types";

const api = new APICore(`${process.env.REACT_APP_IGNITION_API}/` || "https://dev.ignition-api.motorenn.com/");

export const getContractsZipFile = async (id: string) => (await api.get(`documents/${id}/contract`)).data;

export const getDmvDocument = async (id: string): Promise<IDmvDocumentResponse> => (await api.get(`documents/${id}/dmv`)).data;

export const getLeasingDocument = async (id: string) => (await api.get(`documents/${id}/premiere-leasing`)).data;
