interface config {
    API_URL: string;
    APP_NAME: string;
}

const config = {
    API_URL: process.env.REACT_APP_API_URL || "",
    APP_NAME: process.env.REACT_APP_NAME,
};

console.log("CONFIG LOAD", {config, env: process.env});

export default config;
